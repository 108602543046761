.find__search-container {
    width: 100%;
    background-color: #FFF9F2;
    padding: 0px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    margin-bottom: 10px;
}

.find {
    margin-bottom: 50px;
}


.find-mode-toggle-container {
    display: flex;
    justify-content: space-evenly;
    margin-top:14px;
    margin-bottom: 4px;
    width: 100%;
    max-width: 470px;
  }

  .find__separator {
    width: 100%;
    max-width: 450px;
    height: 1px;
    background-color: #ccc;
    margin: 12px auto;
  }
  
  
  .pill-button-find {
    border: 1px solid #4c4c4c;
    border-radius: 19px;
    padding: 6px 16px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;
    color: #000;
    background-color: #fff;
    width: 45%;
  }
  
  .pill-button-find.active {
    background-color: #E9DCD1;
    font-weight: 600;
  }
  

.find__toggle-option {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 10px 0;
}

.find__toggle-image {
    width: 30%;
    aspect-ratio: 1 / 1;
    margin-bottom: 10px;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.find__toggle-option.active .find__toggle-image,
.find__toggle-option.active .find__toggle-text {
    opacity: 1;
    transform: scale(1.1);
}

.find__toggle-option.inactive .find__toggle-image,
.find__toggle-option.inactive .find__toggle-text {
    opacity: 0.5;
}

.find__toggle-text {
    font-size: 14px;
    font-weight: bold;
    color: #333;
    min-height: 20px;
    transition: opacity 0.2s ease-in-out;
}

.find__divider {
    width: 100%;
    height: 1px;
    background-color: #d3d3d3;
    margin-bottom: 10px;
}

.find__search-bar {
    width: 90%;
    max-width: 450px;
    padding: 10px;
    border: 1px solid #d3d3d3;
    border-radius: 6px;
    font-size: 16px;
    color: #333;
    box-sizing: border-box;
    margin-bottom: 10px; /* Add some space between input and button */
}

.find__search-bar::placeholder {
    color: #999;
}

.find__message {
    color: #666;
    font-size: 16px;
    text-align: center;
    margin: 10px 0;
  }
  
  .find__submit-button--disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  .find__divider {
    width: 100%;
    max-width: 450px;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    margin: 10px 0;
}

  

.find__submit-button {
    background-color: #E9DCD1; /* Inverted background color */
    color: black;
    padding: 10px 20px;
    border: 0.5px solid #666666; 
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    max-width: 450px; /* Aligns with the input width */
    width: 100%;
    box-sizing: border-box;
    margin:0px;
    transition: background-color 0.3s ease;
}

.find__submit-button:hover {
    background-color: #E9DCD1; /* Slight color change on hover */
}

.find__results-list {

    max-width: 450px; /* Matches the max-width of the input and button */
    margin: 0 auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

}
