.seriesPage {
    background-color: #FFF9F2;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.series-contentContainer {
    width: 100%;
    max-width: 575px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
}

.series-header-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.series-header {
    max-width: 575px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    text-align: center; /* Center the content inside */
}

.series-userFlagsWrapper {
    max-width: 42%;
    margin-left: 10px;
    word-wrap: break-word;
  }
  
  .series-userFlags {
    font-size: 14px;
    line-height: 1.4;
  }

.series-description-container {
    width: 100%;
    text-align: center;
}

.series-description {
    margin: 0; /* Ensures no weird extra spacing */
    padding: 0; /* Keep it clean */
    margin-bottom: 10px;
}


.series-header h1 {
    font-size: 24px;
    font-weight: bold;
    color: #2F2B27;
    letter-spacing: 0.05em;
}

.series-header p {
    font-size: 16px;
    color: black;
}

.series-author-link {
    cursor: pointer;
    font-weight: bold;
}
.series-authorDetails {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.series-authorAndInfo {
    display: flex;
    flex-direction: column; /* Stack items */
    align-items: center; /* Center horizontally */
    text-align: center; /* Ensure text is centered */
    margin-bottom: 10px;
}

.series-author-link:hover {
    opacity: 0.8;
}

/* Each series entry */
.series-item {
    margin-bottom: 20px;
}

.series-pieceImage {
    width: 100%;
    max-width: 575px;
    margin-bottom: 10px;
    cursor: pointer;
}



.series-authorDetails {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.series-authorPhoto {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 0.5rem;
    object-fit: cover; 
}

.series-authorName {
    font-weight: bold;
    font-size: 1em;
    letter-spacing: 0.08em;
}

/* Genre + length */
.series-lengthIndicator {
    font-size: 0.9em;
    letter-spacing: 0.12em;
}

/* Title & Button */
.series-title {
    font-size: 20px;
    font-weight: bold;
    text-align: center; /* Align title to the left */
    margin-bottom: 17px; /* Add space between title and image */
}



.series-topRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
  }
  

.series-clickable {
    cursor: pointer;
}

.series-partNumber {
    font-weight: normal;
    font-size: 14px;
    color: gray;
}

.series-readButton {
    float: right; /* Keep the read button aligned to the right */
    margin-bottom: 0px;
    background-color: #E9DCD1;
    color: black;
    border: 0.1px solid;
    border-radius: 4px;
    padding: 7px 25px;
    text-align: center;
    cursor: pointer;
    letter-spacing: 0.08em;
}

.series-readButton:hover {
    opacity: 0.8;
}

/* Piece info */
.series-info {
    font-weight: normal;
    font-family: sans-serif;
    letter-spacing: 0.05em;
    text-align: justify;
}

/* Genre and stats */
.series-genreStats {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 10px;
}

.series-stats {
    font-family: sans-serif;
    font-size: 14px;
    margin-top: 3px;
}

.series-style {
    font-family: sans-serif;
    font-size: 14px;
    margin-bottom: 0px;
}

.series-italic {
    font-style: italic;
}

/* Icons */
.series-icon {
    width: 16px;
    height: 16px;
    margin-left: 3px;
    vertical-align: middle;
}

/* Divider */
.series-divider {
    border-bottom: 1px solid black;
    opacity: 0.1;
}

.series-dividers {
    border-bottom: 1px solid black;
    opacity: 0.1;
    margin-bottom: 10px;
}
.series-loginReminder {
    margin: 24px 0 0 0;
    text-align: center;
  }
  
  .series-loginReminder-text {
    font-size: 14px;
    font-family: sans-serif;
    letter-spacing: 0.05em;
    margin-bottom: 10px;
  }
  
  .series-loginReminder-button {
    width: calc(100% - 24px); /* leave some edging on sides */
    background-color: #E9DCD1;
    color: black;
    border: 0.1px solid;
    border-radius: 4px;
    padding: 7px 25px;
    text-align: center;
    cursor: pointer;
    letter-spacing: 0.08em;
  }
  
  .series-loginReminder-button:hover {
    opacity: 0.8;
  }
  
  /* Clean divider below */
  .series-loginReminder-divider {
    border: none;
    border-top: 1px solid #e9dcd1;
    margin-bottom: 24px;
    margin-top: 18px;
  }
  