    .library {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        min-height: 85vh;
        overflow-y: auto;
        padding-bottom: 30px;
    }

    .library__title {
        font-size: 24px;
        color: #2F2B27;
        font-weight: bold;
        text-align: center;
        margin-bottom: 5px;
        font-family: sans-serif;
        letter-spacing: 0.05em;
    }

    .library__section {
        margin: 0 20px;
        border: 1px solid #d3d3d3;
        width: 100%;
        max-width: 450px;
        box-sizing: border-box;
        padding: 20px;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .library__section-title {
        font-size: 16px;
        font-weight: bold;
        color: black;
        width: 100%;
        margin-bottom: 15px;
        letter-spacing: 0.05em;
    }

    .library__post-feed,
    .library__book-feed {
        display: flex;
        overflow-x: auto;
        white-space: nowrap;

        padding: 10px;
    }

    .library__post-item {
        flex: 0 0 auto;
        margin-right: 15px;
        cursor: pointer;
        transition: transform 0.2s;
        width: 120px;
        padding: 2px;
        align-items: center;
    }

    .library__book-item {
        flex: 0 0 auto;
        margin-right: 15px;
        cursor: pointer;
        transition: transform 0.2s;
        width: 120px;
        padding: 2px;
        display: flex;
        flex-direction: column;
        align-items: center;  /* This centers the content horizontally */
    }

    .library__post-item:hover,
    .library__book-item:hover {
        transform: scale(1.05);
    }

    .library__post-image{
        width: 100%;
        height: 120px;
        object-fit: cover;
        border-radius: 4px;
    }


    .library__book-image {
        width: 80px;
        height: 120px;
        object-fit: cover;
        border-radius: 4px;
    }

    .library__post-details,
    .library__book-details {
        margin-top: 5px;
        text-align: center;
    }

    .library__post-title,
    .library__book-title {
        font-size: 14px;
        font-weight: bold;
        color: #2F2B27;
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* Adjust this number to control the number of visible lines */
        -webkit-box-orient: vertical;
        max-height: 2.8em;
        line-height: 1.4em;
    }

    .library__post-author,
    .library__book-author,
    .library__book-retailer {
        font-size: 12px;
        color: #666;
        margin-top: 3px;
    }

    .library__post-feed::-webkit-scrollbar,
    .library__book-feed::-webkit-scrollbar {
        height: 6px;
    }

    .library__post-feed::-webkit-scrollbar-track,
    .library__book-feed::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    .library__post-feed::-webkit-scrollbar-thumb,
    .library__book-feed::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 3px;
    }

    .library__post-feed::-webkit-scrollbar-thumb:hover,
    .library__book-feed::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
    .loading-screen {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        opacity: 1;

      }
      
      .loading-screen.fade-out {
        opacity: 0;
      }
      
      .loading-icon {
        width: 400px; /* Adjust the size as needed */
        height: 400px;
      }

      .library__post-part-number {
        font-weight: normal; /* Adjust as needed */
        color: #666; /* Adjust color as needed */
      }

      .library-mode-toggle-container {
        display: flex;
        justify-content: space-evenly;
        margin-top: 14px;
        margin-bottom: 4px;
        width: 100%;
        max-width: 470px;
      }
      
      .pill-button-library {
        border: 1px solid #4c4c4c;
        border-radius: 19px;
        padding: 6px 16px;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.2s, color 0.2s;
        color: #000;
        background-color: #fff;
        width: 45%;
      }
      
      .pill-button-library.active {
        background-color: #E9DCD1;
        font-weight: 600;
      }
      
    
    .library__toggle-option {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        padding: 10px 0; 
    }
    
    .library__toggle-image {
        width: 30%;
        aspect-ratio: 1 / 1; /* Makes sure the height matches the width */
        margin-bottom: 10px; 
        transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
    }
    
    /* Add spacing at the top so images aren't too close to the header */
    .library__toggle-option.active:first-child {
        /* border-left: 1px solid black;
        border-right: 1px solid rgba(0, 0, 0, 0.1); */
        height: 100%;  /* Ensures full height */
    }
    
    /* Right border for selected option */
    .library__toggle-option.active:last-child {
        /* border-right: 1px solid black;
        border-left: 1px solid rgba(0, 0, 0, 0.1); */
        height: 100%;  /* Ensures full height */
    }
    
    .library__toggle-text {
        font-size: 14px;
        font-weight: bold;
        color: #333;
        min-height: 20px;
        transition: opacity 0.2s ease-in-out;
    }
    
    /* Active = fully visible, inactive = faded */
    .library__toggle-option.active .library__toggle-image,
    .library__toggle-option.active .library__toggle-text {
        opacity: 1;
        transform: scale(1.1);
    }
    
    .library__toggle-option.inactive .library__toggle-image,
    .library__toggle-option.inactive .library__toggle-text {
        opacity: 0.5;
    }
    