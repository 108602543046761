/* Existing .profilePage styles remain unchanged */

/* Loading messages */
.profilePage-loading {
  text-align: center;
  font-size: 16px;
  color: #777;
  margin: 20px 0;
}

/* Mode Toggle */
.profilePage-mode-toggle-container {
  display: flex;
  justify-content: space-evenly;
  gap: 12px;
  margin: 0 auto;
  width: 100%;
  max-width: 575px;
}

.profilePage-pill-button {
  border: 1px solid #4c4c4c;
  border-radius: 19px;
  padding: 6px 16px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
  color: #000;
  background-color: #fff;
  width: 40%;
}

.profilePage-pill-button.active {
  background-color: #E9DCD1;
  font-weight: 600;
}

/* Inactive buttons (for empty feeds) */
.profilePage-pill-button.disabled {
  background-color: #f0f0f0;
  color: #bbb;
  cursor: default;
  border: 1px solid #ddd;
}

.profilePage-pill-button.disabled:hover {
  background-color: #f0f0f0;
}

.profilePage-divider {
  width: 100%;
  max-width: 575px;
  border: none;
  height: 1px;
  background-color: #d3d3d3;
  margin: 10px auto; /* Adjust spacing here if you want less gap */
}
