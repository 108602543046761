.theFeedPage {
    background-color: #FFF9F2;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  
  .theFeed-contentContainer {
    width: 100%;
    max-width: 575px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
  }

  .feed-header-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    margin-bottom: 20px;

    overflow-y: auto;
}

.feed-loading-message {
  color: #666;
  font-size: 16px;
  text-align: center;
  margin: 10px 0;
}
.pill-button.disabled {
  background-color: #f0f0f0;
  color: #bbb;
  cursor: default;
  border: 1px solid #ddd;
}

.pill-button.disabled:hover {
  background-color: #f0f0f0;
}

.feed-header-box {
    margin: 0 0px;
    border: 1px solid #d3d3d3;
    width: 100%;
    max-width: 575px;
    box-sizing: border-box;
    padding: 0px 20px;
    background-color: FFF9F2;
}

.feed-header-title {
    font-size: 20px;
    color: #2F2B27;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
    font-family: sans-serif;
    letter-spacing: 0.05em;
}

.feed-header-sub-title {
    font-size: 16px;
    font-weight: bold;
    color: black;
    width: 100%;
    margin-bottom: 6px;
    margin-top: 17px;
    letter-spacing: 0.05em;
}

.feed-header-text {
    font-size: 16px;
    font-weight: normal;
    color: black;
    width: 100%;
    margin-bottom: 0px;
    letter-spacing: 0.05em;
    line-height: 1.25;
    
}

.feed-header-text-ies {
  font-size: 16px;
  font-weight: normal;
  color: black;
  width: 100%;
  margin-bottom: 20px;
  letter-spacing: 0.05em;
  line-height: 1.25;
}

.feed-header-text,
.feed-header-text-ies {
    text-align: justify; /* Ensures text is justified */
}

 .feed-signup-button {
    background-color: #E9DCD1;
    height: 35px;
    border: 0.1px solid rgb(76, 76, 76);
    border-radius: 2px;
    cursor: pointer;
    margin-bottom: 15px;
    color: black;
    margin-top: 25px;
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    transition: opacity 0.1s;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: normal;
}
.filter-arrow {
  font-size: 8px;
  margin-left: 6px;
  transition: transform 0.2s ease-in-out;
}

.feed-divider {
  width: 100%;
  max-width: 575px;
  border: none;
  height: 1px;
  background-color: #d3d3d3;
  margin: 15px auto;
}

.feed-filter-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E9DCD1;
  color: black;
  font-size: 16px;
  height: 35px;
  border: 0.1px solid rgb(76, 76, 76);
  border-radius: 2px;
  cursor: pointer;
  width: 93%;
  max-width: 575px;
  font-weight: normal;
  transition: opacity 0.1s;
  margin: 10px auto 20px;
}

.feed-mode-toggle-container {
  display: flex;
  justify-content: space-evenly; 
  gap: 12px;
  margin: 12px auto;
  width: 100%;
  max-width: 575px;
}

.pill-button {
  border: 1px solid #4c4c4c;
  border-radius: 19px;
  padding: 6px 16px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
  color: #000;
  background-color: #fff;
  width: 40%;
}

.pill-button.active {
  background-color: #E9DCD1;
  font-weight: 600;
}


.feed-filter-button:hover {
  opacity: 0.8;
}


.feed-filter-button:hover, .feed-signup-button:hover {
    opacity: 0.8;
}

  
  .theFeedItem {
    margin-bottom: 20px; /* Space between items */
  }
  
  .theFeed-pieceImage {
    width: 100%;
    max-width: 575px; /* Adjust as needed */
    margin-bottom: 10px;
    cursor: pointer;
  }

  .authorPhoto {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 0.5rem;
    object-fit: cover; 
  }
  
  .pieceInfo {
    font-weight: normal;
    font-family: sans-serif;
    letter-spacing: 0.05em;
    text-align: justify;
  }

  .clickable {
    cursor: pointer;

  }
  

  .authorAndLengthInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px; /* Space before the piece image */
    
  }
  

  .authorInfo {
    text-align: justify;
    display: flex;
    align-items: center; /* Center items vertically */
    gap: 10px;
  }


  .authorName {
    font-weight: bold; /* Make text bold */
    font-size: 1.0em; /* Adjust size to match h4 */
    font-family: sans-serif;
    letter-spacing: 0.08em;
  }

  .lengthIndicator {
    font-family: sans-serif;
    letter-spacing: 0.12em; /* Slightly spaced, but not bold */
    font-size: 0.9em; /* Slightly smaller than authorName */
    margin-right: 5px;
    padding-top: 6px;
  }

  .title-and-button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  h3 {
    margin: 0; /* Remove margin to align with the button */
    flex-grow: 1; /* Allow the title to take up available space */
    text-align: left;
  }
  
  .read-piece-button {
    background-color: #E9DCD1;
    color: black;
    border: 0.1px solid;
    border-radius: 4px;
    padding-top : 7px;
    padding-bottom : 7px;
    margin-left: 20px;
    padding-left: 25px;
    padding-right: 25px;
    text-align: center;
    cursor: pointer; /* Change cursor to pointer on hover */
    letter-spacing: 0.08em;

  }
  
  .divider {
    border-bottom: 1px solid black;
    opacity: 0.1;
  }
  .genre-style-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px; /* Space before the divider */
    align-self: center;
  }
  
  .genre-style-text {
    /* Add styles for genre and style text here */
    font-family: sans-serif;
    font-size: 14px;
    /* Other styling as needed */
  }

  .footer {
    /* ... your footer styles ... */
    width: 100%;
    flex-shrink: 0;
  }
  
  .lowResultsMessage {
    margin-bottom: 20px;
    font-size: x-large; /* Make the text larger */
    font-family: "Jersey 10", sans-serif;
  }
  .loading-screen {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    height: calc(100vh - 0px); /* Adjust this value based on your header height */
    width: 100%;
  }
  
  .loading-icon {
    width: 400px; /* Adjust the size as needed */
    height: 400px;
  }
  
  /* @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  } */
