.createAccountBox {
  border: 1px solid #d3d3d3;
  border-radius: 2px;
  padding: 20px;
  width: 350px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: flex-start; /* Align content to the left */
  color: black;
}

.createText {
  color: black;
  font-weight: bold;
  margin-bottom: 20px; /* Add spacing between text and button */
}

.createLink {
  font-weight: bold;
  text-decoration: underline;
  color: black;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}
