.bookContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .bookItemsContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .bookItem {
    width: 22%;
    text-align: center; /* Centering the image */
    cursor: pointer;
  }
  
  .bookImage {
    width: 60%; /* Adjust width as needed */
    height: auto; /* Maintain aspect ratio */
    /* Adjust aspect ratio to match your requirements */
  }

  .retryableImageWrapper {
    position: relative;
    display: inline-block;
  }
  
  .fallbackOverlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #E9DCD1; /* Darker version of E9DCD1 */
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    width: 80%;
    max-height: 80%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .authorNameDifferent {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-shadow: 
      -1px -1px 0 #000,  
       1px -1px 0 #000,
      -1px  1px 0 #000,
       1px  1px 0 #000; /* Creates a black outline */
  }
  